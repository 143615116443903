<template>
  <div id="app">
    <v-card
      class="mx-auto grow hidden-sm-and-down"
      elevation="12"
      color="white"
      max-height="420"
    >
      <v-card-text class="black--text">
        <div class="pt-10 pb-5 pl-10 pr-10">
          <div>
            <v-img
              src="/logotipo/logotipo-preto.png"
              alt="Logotipo Top Motors Auto"
              max-width="400px"
            />
          </div>
          <div class="text-center pt-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.username"
                :rules="rule_email"
                label="Email"
                placeholder="Email"
                outlined
                class="font-text"
              />
              <v-text-field
                v-model="user.password"
                :rules="rule_password"
                label="Senha"
                placeholder="Senha"
                outlined
                class="font-text"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              />
              <v-btn
                color="primary"
                class="white--text font-button"
                large
                :disabled="!valid"
                :loading="loading"
                @click="login(user)"
              >
                Entrar
                <v-icon right> mdi-login </v-icon>
              </v-btn>
            </v-form>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto grow d-md-none"
      elevation="12"
      color="white"
      max-height="420"
      max-width="85%"
    >
      <v-card-text class="black--text">
        <div class="pt-10 pb-5 pl-10 pr-10">
          <div>
            <v-img
              src="/logotipo/logotipo-preto.png"
              alt="Logotipo Top Motors Auto"
              max-width="400px"
            />
          </div>
          <div class="text-center pt-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.username"
                :rules="rule_email"
                label="Email"
                placeholder="Email"
                outlined
                class="font-text"
              />
              <v-text-field
                v-model="user.password"
                :rules="rule_password"
                label="Senha"
                placeholder="Senha"
                outlined
                class="font-text"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              />
              <v-btn
                color="primary"
                class="white--text font-button"
                large
                :disabled="!valid"
                :loading="loading"
                @click="login(user)"
              >
                Entrar
                <v-icon right> mdi-login </v-icon>
              </v-btn>
            </v-form>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <div class="mt-10">
      <v-btn
        color="red"
        class="white--text font-button"
        to="/esqueci-minha-senha"
      >
        <v-icon left> mdi-alert-circle </v-icon>
        Esqueci a minha senha
      </v-btn>
    </div>
  </div>
</template>

<script>
import Authentication from "../services/authentication";
export default {
  name: "LoginView",
  title: "Login",
  data: () => ({
    user: {
      username: "tairikjohnny@gmail.com",
      password: "6UMEl8FBaCdAF83Txb2O",
    },
    show1: false,
    valid: true,
    loading: false,
    rule_email: [
      (v) => !!v || "O e-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
    ],
    rule_password: [(v) => !!v || "A senha é obrigatória"],
  }),
  methods: {
    login() {
      this.loading = true;
      Authentication.login(this.user)
        .then((response) => {
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("username", response.data.username);
          sessionStorage.setItem("roles", response.data.roles);
          this.$router.push("/home");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
@import "../app.scss";

#app {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #343533;
}

.font-button {
  font-family: Sora-ExtraBold, sans-serif;
}

.font-text {
  font-family: Sora-Regular, sans-serif;
  font-size: 18px;
}
</style>
