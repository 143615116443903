import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";

export const http = axios.create({
  baseURL: "http://localhost:8080/",
  headers: {
    Accept: "application/json",
  },
});

http.interceptors.request.use((config) => {
  if (sessionStorage.getItem("token")) {
    config.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
  }
  return config;
});

http.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.data.errorCode === "001") {
      Swal.fire({
        title: "<strong>Erro</strong>",
        icon: "error",
        html:
          "<strong>Erro:</strong> " +
          error.response.data.errorCode +
          "<br><strong>Mensagem:</strong> " +
          error.response.data.errorMessage +
          "<br><strong>Detalhes:</strong> " +
          error.response.data.details,
      });
    } else if (error.response.data.errorCode === "002") {
      Swal.fire({
        title: "<strong>Erro</strong>",
        icon: "error",
        html:
          "<strong>Erro:</strong> " +
          error.response.data.errorCode +
          "<br><strong>Mensagem:</strong> " +
          error.response.data.errorMessage +
          "<br><strong>Detalhes:</strong> " +
          error.response.data.details,
      });
    } else if (error.response.data.errorCode === "003") {
      Swal.fire({
        title: "<strong>Erro</strong>",
        icon: "error",
        confirmButtonText: "OK",
        html:
          "<strong>Erro:</strong> " +
          error.response.data.errorCode +
          "<br><strong>Mensagem:</strong> " +
          error.response.data.errorMessage +
          "<br><strong>Detalhes:</strong> " +
          error.response.data.details,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("roles");
          localStorage.removeItem("userId");
          router.push("/");
        }
      });
    } else if (error.response.data.errorCode === "004") {
      Swal.fire({
        title: "<strong>Erro</strong>",
        icon: "error",
        html:
          "<strong>Erro:</strong> " +
          error.response.data.errorCode +
          "<br><strong>Mensagem:</strong> " +
          error.response.data.errorMessage +
          "<br><strong>Detalhes:</strong> " +
          error.response.data.details,
      });
    } else if (error.response.data.errorCode === "999") {
      Swal.fire({
        title: "<strong>Erro</strong>",
        icon: "error",
        html:
          "<strong>Erro:</strong> " +
          error.response.data.errorCode +
          "<br><strong>Mensagem:</strong> " +
          error.response.data.errorMessage +
          "<br><strong>Detalhes:</strong> " +
          error.response.data.details,
      });
    } else {
      Swal.fire({
        title: "<strong>Erro</strong>",
        icon: "error",
        html:
          "<strong>Erro:</strong> " +
          error.response.data.errorCode +
          "<br><strong>Mensagem:</strong> " +
          error.response.data.errorMessage +
          "<br><strong>Detalhes:</strong> " +
          error.response.data.details,
      });
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("roles");
      localStorage.removeItem("userId");
    }
    
    if (error.response.status === 403) {
      alert("Não autorizado!");
    } else if (error.response.status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("roles");
      sessionStorage.removeItem("userId");

      router.push("/");
    }
    throw error;
  }
);
