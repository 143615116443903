<template>
  <v-app>
    <v-navigation-drawer :mini-variant.sync="mini" expand-on-hover app>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              src="https://randomuser.me/api/portraits/women/85.jpg"
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ this.username }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ this.username }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item link to="/home">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/gestao-usuario">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Gestão de Usuários</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/gestao-veiculos">
          <v-list-item-icon>
            <v-icon>mdi-car-back</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Gestão de Veículos</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <main style="height: 100%">
      <router-view />
    </main>
    <!-- <v-footer color="#F9860A" padless app>
      <v-col class="text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>Sistema Top Motors Auto</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
export default {
  data: () => ({
    username: sessionStorage.getItem("username"),
    mini: true,
  }),
  methods: {
    logout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("roles");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
