import { http } from "./config";

export default {
  login: (user) => {
    return http.post("/rest/v1/auth/login", user);
  },
  resetPassword: (user) => {
    return http.post("/rest/v1/auth/reset-password", user);
  },
  updatePassword: (user) => {
    return http.post("/rest/v1/auth/update-password", user);
  },
};
